<template>
  <div v-if="questionList[subIndex]">
    <div class="file" v-if="questionList[subIndex].way_id == 3">
      <iframe
        width="100%"
        height="500px"
        v-if="questionList[subIndex].question"
        :src="
          questionList[subIndex].file_ext == 'pdf'
            ? prefix(
                questionList[subIndex].question
                  ? questionList[subIndex].question
                  : ''
              )
            : prefixFile(
                prefix(
                  questionList[subIndex].question
                    ? questionList[subIndex].question
                    : ''
                )
              )
        "
        frameborder="0"
      ></iframe>
      <div>
        <van-uploader
          v-show="!questionList[subIndex].question"
          :after-read="afterReadFile"
        >
          <van-button type="primary">点击上传附件</van-button>
        </van-uploader>
      </div>

      <!-- 答案 -->
      <div style="margin-top: 20px; text-align: left">
        <van-button
          v-if="!questionList[subIndex].answer_way_id"
          icon="plus"
          type="primary"
          @click="showAction = true"
          >点击添加答案</van-button
        >
        <van-button type="primary" v-show="showChange" @click="clickChange"
          >修改答案类型</van-button
        >
        <!-- {{ questionList[subIndex] }}{{ subIndex }} -->
        <van-field
          v-if="questionList[subIndex].answer_way_id"
          v-model="questionList[subIndex].answer"
          type="textarea"
          @update:model-value="changeTextAnswer"
          label="答案："
        >
          <template v-if="questionList[subIndex].answer_way_id == 2" #input>
            <van-uploader
              v-model="questionList[subIndex].answerArr"
              :max-count="1"
              :before-read="beforeReadImg"
              @click="clickQuestion"
            />
          </template>
          <template v-if="questionList[subIndex].answer_way_id == 3" #input>
            <van-uploader :after-read="afterReadeFile">
              <van-button
                v-if="
                  !(
                    questionList[subIndex].answer_way_id == 3 &&
                    questionList[subIndex].answer
                  )
                "
                icon="plus"
                type="primary"
                >上传附件答案</van-button
              >
            </van-uploader>
          </template>
        </van-field>

        <!-- 解析 -->
        <van-field
          label="答案解析："
          v-if="questionList[subIndex].answer_way_id"
        >
          <template #input>
            <van-uploader
              v-model="questionList[subIndex].analysisArr"
              :max-count="1"
              :before-read="beforeReadPar"
              @click-upload="clickSis"
            />
          </template>
        </van-field>

        <iframe
          v-if="
            questionList[subIndex].answer_way_id == 3 &&
            questionList[subIndex].answer
          "
          :src="
            questionList[subIndex].answer_file_ext == 'pdf'
              ? prefix(questionList[subIndex].answer)
              : prefixFile(prefix(questionList[subIndex].answer))
          "
          frameborder="0"
          width="100%"
          height="500px"
        ></iframe>

        <van-action-sheet
          v-model:show="showAction"
          :actions="actions"
          @select="onSelectAnswerType"
        />
      </div>

      <!-- 切图 -->
      <van-popup
        v-model:show="showCut"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <vue-cropper
          v-if="isShowCro"
          :aspect-ratio="option.cropperAspectWH"
          ref="cropper"
          :src="option.imgSrc"
          :ready="option.cropImage"
          :zoom="option.cropImage"
          :cropmove="option.cropImage"
          style="width: 100%; height: 100%"
        ></vue-cropper>

        <!-- <button @click="getData">获取截图</button>
        <button @click="() => (showCut = !showCut)">
          {{ showCut ? "隐藏" : "展示" }}
        </button> -->
        <div class="fnBTn">
          <span @click="cancel">取消</span>
          <span @click="deteFn">确定</span>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { ref, defineProps, defineEmits, watch } from "vue";
import { Toast } from "vant";
import { uploadFile, uploadImgBase } from "@/service/upload.js";
import { prefix, prefixFile } from "@/common/js/utils.js";

let props = defineProps(["questionList", "subIndex"]);
const emit = defineEmits([
  "changeQuestion",
  "changeAnswer",
  "addAnswer",
  "changeAnswerArr",
]);

let fileName = ref("");
const actions = [
  { name: "文字答案", value: 1 },
  { name: "图片答案", value: 2 },
  // { name: "附件答案", value: 3 },
];
let answerType = ref(0);

// 组件显示隐藏状态
let showAction = ref(false);
let showChange = ref(false);
let showCut = ref(false);

// 截图工具配置
let option = ref({
  imgSrc: "@/assets/new.png",
  autoCrop: true,
  dialogVisible: false,
  img_size: "",
  max_fuyuan_defaultSrc: [],
  // cropperAspectWH: 1, //图片剪裁宽高比例
  reader: "",
  e_photo_file: "",
  e_photo_filefile: "",
  http_cropImg: "", //带http
  cropImg: "", //默认图片不带http
});

// 文件读取后
const afterReadFile = async (file) => {
  // 验证文件类型
  if (
    file.file.type == "text/plain" ||
    file.file.type == "image/png" ||
    file.file.type == "image/jpeg"
  ) {
    Toast.fail("只能上传doc,docx,pdf,ppt,pptx,xls,xlsx类型的文件");
    return false;
  }

  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    Toast.success("上传成功");
    fileName.value = res.data.file.name;
    emit("changeQuestion", res.data.file.url, res.data.file.suffix);
  }
};

// 处理答案
const changeTextAnswer = (e) => {
  emit("changeAnswer", e);
  emit("addAnswer", 1);
};
const clickChange = () => {
  showAction.value = true;
};

// 图片上传前处理
const beforeReadImg = (file, detail) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file, detail);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};
const beforeReadPar = (file, detail) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file, detail);
  }
};

// 图片背景base64
let isShowCro = ref(false);
function imageToBase64(file, detail) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    isShowCro.value = true;
    option.value.imgSrc = reader.result;
    // option.value.detail = detail;
  };
}

// 附件上传
const afterReadeFile = async (file) => {
  // 验证文件类型
  if (
    file.file.type == "text/plain" ||
    file.file.type == "image/png" ||
    file.file.type == "image/jpeg"
  ) {
    Toast.fail("只能上传doc,docx,pdf,ppt,pptx,xls,xlsx类型的文件");
    return false;
  }

  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    emit("changeAnswer", res.data.file.url);
    emit("addAnswer", 3, res.data.file.suffix);
  }
};

let upType = 0;
const clickQuestion = () => {
  upType = 1;
};
const clickSis = () => {
  upType = 2;
};

// 取消按钮
const cancel = () => {
  isShowCro.value = false;
  showCut.value = false;
};

// 截图工具截取图片方法
const cropper = ref(null);
const deteFn = async () => {
  // console.log(cropper.value.src);
  let formData = new FormData();
  let base = cropper.value.src;
  formData.append("imgBase", base);
  let res = await uploadImgBase(formData);
  if (res) {
    if (upType == 1) {
      emit("changeAnswer", res.data.file.url);
    }
    if (upType == 2) {
      emit("changeAnalysis", res.data.file.url);
    }
    isShowCro.value = false;
    showCut.value = false;
  }
};

watch(
  () => props.subIndex,
  () => {
    showChange.value = false;
  }
);

// 选择上传答案类型（图片类型或附件类型）
const onSelectAnswerType = (e) => {
  showAction.value = false;
  answerType.value = e.value;
  showChange.value = true;
  emit("changeAnswerArr");
  emit("addAnswer", e.value);
};
</script>
<style lang="less" scoped>
.file {
  width: 100%;
  text-align: center;
}

.cropper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  height: 90%;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.fnBTn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);

  span {
    height: 100%;
    font-size: 12px;
    color: #fff;
  }
  span:nth-child(1) {
    margin-left: 10px;
  }
  span:nth-child(2) {
    margin-right: 10px;
  }
}

// :deep(.van-field__label) {
//   display: flex;
//   justify-content: start;
//   align-items: center;
// }
</style>
