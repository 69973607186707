<template>
  <div v-if="questionList[subIndex]">
    <div class="moduleBox" v-if="questionList[subIndex].way_id == 2">
      <span>{{
        questionList[subIndex].type_id == 1
          ? "选择题"
          : questionList[subIndex].type_id == 2
          ? "判断题"
          : "主观题"
      }}</span>
      <div class="caseType">
        <van-field name="uploader" label="上传题目">
          <template #input>
            <van-uploader
              v-model="questionList[subIndex].questionArr"
              :before-read="beforeRead"
              :max-count="1"
              multiple
              @click-upload="clickQuestion"
            />
            <!-- <van-uploader v-model="fileList" multiple /> -->
          </template>
        </van-field>
        <!-- <van-field
          label="上传答案"
          placeholder="请输入答案"
          v-model="questionList[subIndex].answer"
          @update:model-value="changeTextAnswer"
        >
          <template #input v-if="questionList[subIndex].type == 3">
            <van-uploader
              v-model="questionList[subIndex].answerArr"
              :before-read="ansBefor"
              :max-count="1"
              multiple
              @click-upload="clickAnswer"
            />
          </template>
        </van-field> -->

        <!-- 答案 -->
        <div style="margin-top: 20px">
          <van-button
            v-if="!questionList[subIndex].answer_way_id"
            icon="plus"
            type="primary"
            @click="showAction = true"
            >点击添加答案</van-button
          >
          <van-button type="primary" v-show="showChange" @click="clickChange"
            >修改答案类型</van-button
          >
          <!-- {{ questionList[subIndex].select_answer }} -->
          <van-field
            v-if="
              questionList[subIndex].answer_way_id == 1 &&
              questionList[subIndex].type_id == 1
            "
            label="选择类型："
          >
            <template #input>
              <van-radio-group
                v-model="questionList[subIndex].select_type_id"
                direction="horizontal"
              >
                <van-radio :name="1" shape="square">单选</van-radio>
                <van-radio :name="2" shape="square">多选</van-radio>
              </van-radio-group></template
            >
          </van-field>
          <van-field
            v-if="questionList[subIndex].answer_way_id"
            v-model="questionList[subIndex].answer"
            type="textarea"
            @update:model-value="changeTextAnswer"
            label="答案"
          >
            <template
              v-if="
                questionList[subIndex].answer_way_id == 1 &&
                questionList[subIndex].type_id == 1
              "
              #input
            >
              <van-radio-group
                v-model="questionList[subIndex].answer"
                direction="horizontal"
                @change="changeSelectAnswer"
              >
                <div
                  class="answerCheck"
                  v-for="(item, index) in questionList[subIndex].select_answer"
                >
                  <div
                    class="close"
                    @click="deleOPtion(index)"
                    v-if="
                      questionList[subIndex].select_answer.length == index + 1
                    "
                  >
                    <van-icon name="cross" />
                  </div>
                  <van-radio :name="item.key" shape="square">{{
                    item.key
                  }}</van-radio>
                </div>
                <div @click="addAnswerOPtion">
                  <van-icon name="plus" />添加选项
                </div>
              </van-radio-group>
            </template>

            <!-- 多选 -->
            <template
              #input
              v-if="
                questionList[subIndex].answer_way_id == 1 &&
                questionList[subIndex].type_id == 1 &&
                questionList[subIndex].select_type_id == 2
              "
            >
              <div
                class="checks"
                v-for="(item, index) in questionList[subIndex].select_answer"
              >
                <div
                  class="close"
                  @click="deleOPtion(index)"
                  v-if="
                    questionList[subIndex].select_answer.length == index + 1
                  "
                >
                  <van-icon name="cross" />
                </div>
                <van-checkbox
                  v-model="item.isAnswer"
                  :name="item.key"
                  shape="square"
                  @change="changeChecks(index, $event)"
                  >{{ item.key }}</van-checkbox
                >
              </div>
              <div @click="addAnswerOPtion">
                <van-icon name="plus" />添加选项
              </div>
            </template>

            <template v-if="questionList[subIndex].answer_way_id == 2" #input>
              <van-uploader
                v-model="questionList[subIndex].answerArr"
                :max-count="1"
                :before-read="beforeRead"
                @click-upload="clickAnswer"
              />
            </template>
            <template v-if="questionList[subIndex].answer_way_id == 3" #input>
              <van-uploader :after-read="afterReadeFile">
                <van-button
                  v-if="
                    !(
                      questionList[subIndex].answer_way_id == 3 &&
                      questionList[subIndex].answer
                    )
                  "
                  icon="plus"
                  type="primary"
                  >上传附件</van-button
                >
              </van-uploader>
            </template>
          </van-field>

          <!-- 解析 -->
          <van-field
            label="答案解析"
            v-if="questionList[subIndex].answer_way_id"
          >
            <template #input>
              <van-uploader
                v-model="questionList[subIndex].analysisArr"
                :max-count="1"
                :before-read="beforeReadPar"
                @click-upload="clickAnswerSis"
              />
            </template>
          </van-field>

          <iframe
            v-if="
              questionList[subIndex].answer_way_id == 3 &&
              questionList[subIndex].answer
            "
            :src="
              questionList[subIndex].answer_file_ext == 'pdf'
                ? prefix(questionList[subIndex].answer)
                : prefixFile(prefix(questionList[subIndex].answer))
            "
            frameborder="0"
            width="100%"
            height="500px"
          ></iframe>

          <van-action-sheet
            v-model:show="showAction"
            :actions="actions"
            @select="onSelectAnswerType"
          />
        </div>
      </div>
    </div>
  </div>

  <van-popup
    v-model:show="showCut"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <vue-cropper
      v-if="isShowCro"
      :aspect-ratio="option.cropperAspectWH"
      ref="cropper"
      :src="option.imgSrc"
      :ready="option.cropImage"
      :zoom="option.cropImage"
      :cropmove="option.cropImage"
      style="width: 100%; height: 100%"
    ></vue-cropper>

    <!-- <button @click="getData">获取截图</button>
        <button @click="() => (showCut = !showCut)">
          {{ showCut ? "隐藏" : "展示" }}
        </button> -->
    <div class="fnBTn">
      <span @click="cancel">取消</span>
      <span @click="deteFn">确定</span>
    </div>
  </van-popup>
</template>

<script setup>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import { Toast } from "vant";
import { ref, defineProps, defineEmits, watch } from "vue";
import { uploadImgBase, uploadFile } from "@/service/upload.js";
import { prefix } from "@/common/js/utils.js";

let props = defineProps(["questionList", "subIndex"]);

let emit = defineEmits([
  "changeQuestion",
  "changeAnswer",
  "addAnswer",
  "changeAnswerArr",
  "changeAnalysis",
  "addAnswerOPtions",
  "deleteAnswerOption",
  "changeSelectAnswer",
  "changeChecks",
]);
// 截图工具配置
let option = ref({
  imgSrc: "@/assets/new.png",
  autoCrop: true,
  dialogVisible: false,
  img_size: "",
  max_fuyuan_defaultSrc: [],
  // cropperAspectWH: 1, //图片剪裁宽高比例
  autoCropWidth: 1230,
  autoCropHeight: 1150,
  reader: "",
  e_photo_file: "",
  e_photo_filefile: "",
  http_cropImg: "", //带http
  cropImg: "", //默认图片不带http
});

const actions = [
  { name: "文字答案", value: 1 },
  { name: "图片答案", value: 2 },
  // { name: "附件答案", value: 3 },
];

// 组件显示隐藏状态
let showCut = ref(false);
let showAction = ref(false);
let showChange = ref(false);
let answerType = ref(0);

// 上传题目图片前处理
const beforeRead = (file, detail) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file, detail);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};

const beforeReadPar = (file) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};

/**
 * 处理答案
 */

// 文字（选择题和判断题）答案
const changeTextAnswer = (e) => {
  emit("changeAnswer", e);
  emit("addAnswer", 1);
};
const clickChange = () => {
  showAction.value = true;
  emit("changeAnswerArr");
};

watch(
  () => props.subIndex,
  () => {
    showChange.value = false;
  }
);

// 选择上传答案类型（图片类型或附件类型）
const onSelectAnswerType = (e) => {
  showAction.value = false;
  answerType.value = e.value;
  showChange.value = true;
  emit("addAnswer", e.value);
};

// 附件上传
const afterReadeFile = async (file) => {
  // 验证文件类型
  if (
    file.file.type == "text/plain" ||
    file.file.type == "image/png" ||
    file.file.type == "image/jpeg"
  ) {
    Toast.fail("只能上传doc,docx,pdf,ppt,pptx,xls,xlsx类型的文件");
    return false;
  }

  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    emit("changeAnswer", res.data.file.url);
    emit("addAnswer", 3, res.data.file.suffix);
  }
};

// 图片背景base64
let isShowCro = ref(false);
function imageToBase64(file, detail) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    isShowCro.value = true;
    option.value.imgSrc = reader.result;
    // option.value.detail = detail;
  };
}

let queOran = 0;
const clickQuestion = () => {
  queOran = 1;
};
const clickAnswer = () => {
  queOran = 2;
};
const clickAnswerSis = () => {
  queOran = 3;
};

// // 截图工具截取图片方法
// const cropper = ref(null);
// const getData = () => {
//   cropper.value.getCropData(async (data) => {
//     let formData = new FormData();
//     formData.append("imgBase", data);
//     showCut.value = false;
//     let res = await uploadImgBase(formData);
//     if (res) {
//       if (queOran == 1) {
//         emit("changeQuestion", res.data.file.url);
//       } else if (queOran == 2) {
//         emit("changeAnswer", res.data.file.url);
//         emit("addAnswer", 2);
//       }
//     }
//   });
// };

// 取消按钮
const cancel = () => {
  isShowCro.value = false;
  showCut.value = false;
};

// 确定按钮
const cropper = ref(null);
const deteFn = async () => {
  // console.log(cropper.value.src);
  let formData = new FormData();
  let base = cropper.value.src;
  formData.append("imgBase", base);
  let res = await uploadImgBase(formData);
  if (res) {
    if (queOran == 1) {
      emit("changeQuestion", res.data.file.url);
    } else if (queOran == 2) {
      emit("changeAnswer", res.data.file.url);
      emit("addAnswer", 2);
    } else if (queOran == 3) {
      emit("changeAnalysis", res.data.file.url);
    }
    isShowCro.value = false;
    showCut.value = false;
  }
};

// 添加选项
const addAnswerOPtion = (index) => {
  emit("addAnswerOPtions", index);
};
// 删除选项
const deleOPtion = (index) => {
  emit("deleteAnswerOption", index);
};
// 修改
const changeSelectAnswer = (e) => {
  emit("changeAnswer", e);
  emit("changeSelectAnswer");
};

const changeChecks = (index, e) => {
  emit("changeChecks", index, e);
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin";
.moduleBox {
  width: 100%;
  position: relative;
  margin-bottom: 2vw;
}
.moduleBox > span {
  padding-left: 16px;
  font-size: 15px;
}
.cropper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  height: 90%;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.fnBTn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);

  span {
    height: 100%;
    font-size: 12px;
    color: #fff;
  }
  span:nth-child(1) {
    margin-left: 10px;
  }
  span:nth-child(2) {
    margin-right: 10px;
  }
}

// :deep(.van-field__label) {
//   display: flex;
//   justify-content: start;
//   align-items: center;
// }

.answerCheck {
  position: relative;
  margin-right: 15px;
  .close {
    position: absolute;
    font-size: 10px;
    color: red;
    right: 2px;
    top: -13px;
  }
}

.checks {
  position: relative;
  margin-right: 20px;
  .close {
    position: absolute;
    font-size: 10px;
    color: red;
    right: -8px;
    top: -13px;
  }
}
</style>
