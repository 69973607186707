<template>
  <div class="case">
    <div class="navBar">
      <van-nav-bar
        title="添加学案"
        left-text="返回"
        right-text="完成"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
    </div>
    <div style="width: 200px; margin-top: 6px">
      <van-pagination
        v-model="currentPage"
        :page-count="questionList.length"
        :show-page-size="5"
        @change="changePagination"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
    </div>
    <div class="content">
      <!-- 叉号 -->
      <div class="reset" @click="resetFn">
        <van-icon name="cross" />
      </div>
      <div class="conIn">
        <div style="text-align: center">
          <van-button
            v-if="showAddBtn && !questionList[subIndex].question"
            type="primary"
            @click="changeShowAction"
          >
            添加题目
          </van-button>
        </div>

        <!-- 富文本 -->
        <Editor
          :questionList="questionList"
          :subIndex="subIndex"
          ref="textEditor"
          @changeQuestion="changeQuestion"
          @changeAnswer="changeAnswer"
          @addAnswer="addAnswer"
          @changeQuestionOption="changeQuestionOption"
          @changeAnswerArr="changeAnswerArr"
          @changeAnalysis="changeAnalysis"
          @addAnswerOPtions="addAnswerOPtions"
          @deleteAnswerOption="deleteAnswerOption"
          @changeSelectAnswer="changeSelectAnswer"
          @changeChecks="changeChecks"
        ></Editor>

        <!-- 图片 -->
        <UploadAndCut
          :questionList="questionList"
          :subIndex="subIndex"
          @changeQuestion="changeQuestion"
          @changeAnswer="changeAnswer"
          @addAnswer="addAnswer"
          @changeAnswerArr="changeAnswerArr"
          @changeAnalysis="changeAnalysis"
          @addAnswerOPtions="addAnswerOPtions"
          @deleteAnswerOption="deleteAnswerOption"
          @changeSelectAnswer="changeSelectAnswer"
          @changeChecks="changeChecks"
        ></UploadAndCut>

        <!-- 上传附件 -->
        <FileCase
          :questionList="questionList"
          :subIndex="subIndex"
          @changeQuestion="changeQuestion"
          @changeAnswer="changeAnswer"
          @addAnswer="addAnswer"
          @changeAnswerArr="changeAnswerArr"
          @changeAnalysis="changeAnalysis"
          @addAnswerOPtions="addAnswerOPtions"
          @deleteAnswerOption="deleteAnswerOption"
          @changeSelectAnswer="changeSelectAnswer"
        ></FileCase>
      </div>
    </div>
    <div class="fnBtns">
      <van-button
        v-if="questionList.length - 1 == subIndex"
        type="primary"
        size="small"
        @click="saveNext('saveAndNext')"
        >保存并添加下一题</van-button
      >
      <van-button v-else type="primary" size="small" @click="saveNext('save')"
        >保存</van-button
      >
    </div>

    <!-- 点击添加学案后弹出的选项 -->
    <van-action-sheet
      v-model:show="showAction"
      :actions="actions"
      @select="onSelect"
    />
    <van-action-sheet
      v-model:show="showTypeAction"
      :actions="TypeActions"
      @select="onSelectType"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  apiSaveQuestionAnswer,
  apiCreateSaveCase,
  apiCaseQuestionDel,
  apiGetQuestionList,
} from "@/service/teacher.js";
import Editor from "../components/Editor.vue";
import UploadAndCut from "../components/UploadAndCut.vue";
import FileCase from "../components/FileCase.vue";
import { prefix } from "@/common/js/utils.js";
import { Toast } from "vant";

const router = useRouter();
const route = useRoute();

// 组件显示隐藏状态
let showAction = ref(false);
let showTypeAction = ref(false);
let showAddBtn = ref(true);

// 组件数据
let way_id = ref(0);
let type_id = ref("");
let currentPage = ref(1);
let caseLength = ref(1);
// let question = ref("");
// let answer = ref("");
// let optionsJson = ref([]);
let questionList = ref([{ question: "", way_id: "" }]);
let subIndex = ref(0);
let isDel = ref(false);

const getQuestionList = async () => {
  let res = await apiGetQuestionList({ case_id: route.query.case_id });
  if (res) {
    caseLength.value = res.data.length;
    if (res.data.length) {
      showAddBtn.value = false;
      questionList.value = res.data;
      questionList.value.forEach((item, index) => {
        // if (item.way == 2) {
        //   questionList.value[index].questionArr = [
        //     { url: prefix(item.question) },
        //   ];
        //   if (item.type == 3) {
        //     questionList.value[index].answerArr = [
        //       { url: prefix(item.answer) },
        //     ];
        //   }
        // }

        if (item.type_id == 1) {
          if (item.options) {
            item.select_answer = item.options.map((subItem) => {
              return {
                key: subItem.key,
                isAnswer: subItem.is_answer,
              };
            });
          }else{
            item.select_answer = []
          }
        }

        item.questionArr = [{ url: prefix(item.question) }];
        item.answerArr = [{ url: prefix(item.answer) }];
        item.analysisArr = item.analysis
          ? [{ url: prefix(item.analysis) }]
          : [];
      });
    } else {
      const arr = [...questionList.value];
      arr[subIndex.value] = { question: "" };
      questionList.value = arr;
      showAddBtn.value = true;
    }
  }
};
getQuestionList();

const actions = [
  { name: "文字", value: 1 },
  { name: "拍照/照片", value: 2 },
  { name: "附件", value: 3 },
];
const TypeActions = [
  { name: "选择题", value: 1 },
  { name: "判断题", value: 2 },
  { name: "主观题", value: 3 },
];

const changeShowAction = () => {
  showAction.value = true;
};

// 选择题目类型（题目或者AI）
const onSelect = (e) => {
  showAction.value = false;
  way_id.value = e.value;
  if (e.value == 1) {
    showTypeAction.value = true;
  }
  if (e.value == 2) {
    showTypeAction.value = true;
  }
  if (e.value == 3) {
    if (questionList.value[subIndex.value].question) {
      questionList.value.push({
        way_id: 3,
        type_id: 3,
        question: "",
        answer: "",
        case_id: route.query.case_id,
        id: 0,
        question_option: [],
        answer_file_ext: "",
        answer_way_id: 0,
        analysis: "",
        select_type_id: 1,
        select_answer: [],
      });
    } else {
      questionList.value[subIndex.value] = {
        way_id: 3,
        type_id: 3,
        question: "",
        answer: "",
        case_id: route.query.case_id,
        id: 0,
        question_option: [],
        answer_file_ext: "",
        answer_way_id: 0,
        analysis: "",
        select_type_id: 1,
        select_answer: [],
      };
    }

    currentPage.value = questionList.value.length;
    subIndex.value = questionList.value.length - 1;
    showAddBtn.value = false;
  }
};

// 选择题目类型（选择题，判断题）
const onSelectType = (e) => {
  type_id.value = e.value;

  if (type_id.value) {
    showAddBtn.value = false;
  } else {
    showAddBtn.value = true;
  }

  showTypeAction.value = false;
  if (questionList.value[subIndex.value].question) {
    questionList.value.push({
      way_id: way_id.value,
      type_id: type_id.value,
      question: "",
      answer: "",
      case_id: route.query.case_id,
      id: 0,
      question_option: [],
      answer_file_ext: "",
      answer_way_id: 0,
      select_type_id: 1,
      select_answer: [],
    });
  } else {
    questionList.value[subIndex.value] = {
      way_id: way_id.value,
      type_id: type_id.value,
      question: "",
      answer: "",
      case_id: route.query.case_id,
      id: 0,
      question_option: [],
      answer_file_ext: "",
      answer_way_id: 0,
      select_type_id: 1,
      select_answer: [],
    };
  }

  currentPage.value = questionList.value.length;
  subIndex.value = questionList.value.length - 1;
};

//删除按钮（叉号）
const resetFn = async () => {
  isDel.value = true;
  if (!questionList.value[subIndex.value].id) {
    if (!questionList.value[subIndex.value].case_id) {
      Toast.fail("没有可删除的学案");
      return false;
    }
    await getQuestionList();
    subIndex.value = questionList.value.length - 1;
    return false;
  }
  showAddBtn.value = true;
  let formData = new FormData();
  formData.append("case_id", route.query.case_id);
  formData.append("question_id", questionList.value[subIndex.value].id);
  let res = await apiCaseQuestionDel(formData);
  if (res) {
    // await getQuestionList();
    // if (subIndex.value == 0) {
    //   subIndex.value = 0;
    // } else {
    //   subIndex.value = questionList.value.length - 1;
    // }
    router.go(0);
    // subIndex.value == 0 ? (subIndex.value = 0) : subIndex.value--;
    // const arr = [...questionList.value];
    // arr[subIndex.value] = { question: "" };
    // questionList.value = arr;
  }
};

// 保存并下一题
const saveNext = async (type) => {
  let check = await saveQuestionItem(subIndex.value);
  if (check) {
    Toast.success("保存成功");
    if (type == "saveAndNext") {
      changeShowAction();
    }
  }
};

// 修改问题
const changeQuestion = (val, file_ext) => {
  questionList.value[subIndex.value].question = val;
  questionList.value[subIndex.value].file_ext = file_ext;
  questionList.value[subIndex.value].questionArr = [{ url: prefix(val) }];
};

// 修改答案
const changeAnswer = (val) => {
  questionList.value[subIndex.value].answer = val;
  questionList.value[subIndex.value].answerArr = [{ url: prefix(val) }];
};

const changeSelectAnswer = () => {
  
  questionList.value[subIndex.value].select_answer.forEach((item) => {
    if (item.key == questionList.value[subIndex.value].answer) {
      item.isAnswer = 1;
    } else {
      item.isAnswer = 0;
    }
  });
};

// 修改答案解析
const changeAnalysis = (val) => {
  questionList.value[subIndex.value].analysis = val;
  questionList.value[subIndex.value].analysisArr = [{ url: prefix(val) }];
};

const changeAnswerArr = () => {
  questionList.value[subIndex.value].answer = "";
  questionList.value[subIndex.value].answerArr = [];
};

// 切换题（如切换第一第二题）
const changePagination = (index) => {
  subIndex.value = index - 1;
};

// 点击头部返回按钮
const onClickLeft = () => {
  router.go(-1);
};

// 点击头部右侧发布按钮
const onClickRight = async () => {
  let check;
  for (let i = 0; i < questionList.value.length; i++) {
    check = await saveQuestionItem(i);
    if (!check) {
      continue;
    }
  }
  if (check) {
    router.go(-1);
  }

  // if (check) {
  //   let formData = new FormData();
  //   formData.append("case_id", route.query.case_id);
  //   // let res = await apiCreateSaveCase(formData);
  //   if (res) {
  //     router.push("/teacher/case/list");
  //   }
  // }
};

// 修改选项
const changeQuestionOption = (val, index) => {
  questionList.value[subIndex.value].question_option[index].text = val;
};

// 保存并添加下一题按钮
let textEditor = ref(null);
const saveQuestionItem = async (index) => {
  let formData = new FormData();
  let wayId = questionList.value[index].way_id
    ? questionList.value[index].way_id
    : way_id.value;
  let typeId = questionList.value[index].type_id
    ? questionList.value[index].type_id
    : type_id.value;
  formData.append("case_id", route.query.case_id);
  formData.append("question", questionList.value[index].question);
  formData.append("answer", questionList.value[index].answer);
  formData.append("way_id", wayId);
  formData.append("type_id", typeId);
  formData.append("answer_way_id", questionList.value[index].answer_way_id);
  formData.append(
    "select_type_id",
    questionList.value[index].select_type_id
      ? questionList.value[index].select_type_id
      : ""
  );
  formData.append("order_index", index + 1);
  formData.append(
    "analysis",
    questionList.value[index].analysis ? questionList.value[index].analysis : ""
  );
  formData.append(
    "optionsJson",
    (
      questionList.value[index].select_answer
        ? questionList.value[index].select_answer.length
        : ""
    )
      ? JSON.stringify(questionList.value[index].select_answer)
      : []
  );

  if (questionList.value[index].id) {
    formData.append("question_id", questionList.value[index].id);
  }

  let res = await apiSaveQuestionAnswer(formData);
  if (res) {
    questionList.value[index].id = res.data.question_id;
    // resetFn();
    return true;
  } else {
    return false;
  }
};

// 添加答案
const addAnswer = (val, fileType) => {
  questionList.value[subIndex.value].answer_way_id = val;
  questionList.value[subIndex.value].answer_file_ext = fileType;
};

// 添加答案选项
const addAnswerOPtions = () => {
  var arr = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  questionList.value[subIndex.value].select_answer.push({
    key: arr[questionList.value[subIndex.value].select_answer.length],
    isAnswer: 0,
  });
};

// 删除选项
const deleteAnswerOption = (index) => {
  questionList.value[subIndex.value].select_answer.splice(index, 1);
};

// 多选题修改
const changeChecks = (index) => {
  if (questionList.value[subIndex.value].select_answer[index].isAnswer) {
    questionList.value[subIndex.value].select_answer[index].isAnswer = 1;
  } else {
    questionList.value[subIndex.value].select_answer[index].isAnswer = 0;
  }

  var arr = [];
  questionList.value[subIndex.value].select_answer.forEach((item) => {
    if (item.isAnswer) {
      arr.push(item.key);
    }
  });
  questionList.value[subIndex.value].answer = arr.join(",");
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 6px;
  background-color: #fff;
  position: relative;
  .reset {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    position: absolute;
    z-index: 1;
  }
}

.fnBtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .van-button {
    margin-right: 10px;
  }
}

.conIn {
  width: 90%;
}
</style>
