<template>
  <div class="editor" v-if="questionList[subIndex]">
    <div v-if="questionList[subIndex].way_id == 1">
      <div>
        <span style="font-size: 20px">{{
          questionList[subIndex].type_id == 1
            ? "选择题"
            : questionList[subIndex].type_id == 2
            ? "判断题"
            : "主观题"
        }}</span>
      </div>
      <div style="border: 1px solid #ccc; margin-top: 10px">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 300px; overflow-y: hidden"
          v-model="questionList[subIndex].question"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
          @onChange="handleChange"
        />
      </div>
    </div>

    <!-- 选项 -->
    <!-- <div class="question_option" v-if="questionList[subIndex].question_option">
      <div v-for="(item, index) in questionList[subIndex].question_option">
        <van-field
          v-model="item.text"
          placeholder="请输入选项"
          @update:model-value="changeOPtion(index, $event)"
        />
      </div>
    </div> -->

    <!-- 答案 -->
    <div v-if="questionList[subIndex].way_id == 1" style="margin-top: 10px">
      <!-- {{ questionList[subIndex] }} -->
      <div>
        <van-button
          v-if="!questionList[subIndex].answer_way_id"
          icon="plus"
          type="primary"
          @click="showAction = true"
          >点击添加答案</van-button
        >
        <van-button type="primary" v-show="showChange" @click="clickChange"
          >修改答案类型</van-button
        >
        <!-- {{ questionList[subIndex].answer }}{{ subIndex }} -->
        <van-field
          v-if="
            questionList[subIndex].answer_way_id == 1 &&
            questionList[subIndex].type_id == 1
          "
          label="选择类型："
        >
          <template #input>
            <van-radio-group
              v-model="questionList[subIndex].select_type_id"
              direction="horizontal"
            >
              <van-radio :name="1" shape="square">单选</van-radio>
              <van-radio :name="2" shape="square">多选</van-radio>
            </van-radio-group></template
          >
        </van-field>
        <van-field
          v-if="questionList[subIndex].answer_way_id == 1"
          v-model="questionList[subIndex].answer"
          type="textarea"
          label="答案："
        >
          <!-- 单选 -->
          <template
            v-if="
              questionList[subIndex].answer_way_id == 1 &&
              questionList[subIndex].type_id == 1 &&
              questionList[subIndex].select_type_id == 1
            "
            #input
          >
            <van-radio-group
              v-model="questionList[subIndex].answer"
              direction="horizontal"
              @change="changeSelectAnswer"
            >
              <div
                class="answerCheck"
                v-for="(item, index) in questionList[subIndex].select_answer"
              >
                <div
                  class="close"
                  @click="deleOPtion(index)"
                  v-if="
                    questionList[subIndex].select_answer.length == index + 1
                  "
                >
                  <van-icon name="cross" />
                </div>
                <van-radio :name="item.key" shape="square">{{
                  item.key
                }}</van-radio>
              </div>
              <div @click="addAnswerOPtion">
                <van-icon name="plus" />添加选项
              </div>
            </van-radio-group>
          </template>

          <!-- 多选 -->
          <template
            #input
            v-if="
              questionList[subIndex].answer_way_id == 1 &&
              questionList[subIndex].type_id == 1 &&
              questionList[subIndex].select_type_id == 2
            "
          >
            <div
              class="checks"
              v-for="(item, index) in questionList[subIndex].select_answer"
            >
              <div
                class="close"
                @click="deleOPtion(index)"
                v-if="questionList[subIndex].select_answer.length == index + 1"
              >
                <van-icon name="cross" />
              </div>
              <van-checkbox
                v-model="item.isAnswer"
                :name="item.key"
                shape="square"
                @change="changeChecks(index, $event)"
                >{{ item.key }}</van-checkbox
              >
            </div>
            <div @click="addAnswerOPtion"><van-icon name="plus" />添加选项</div>
          </template>
        </van-field>
        <van-field
          v-if="
            questionList[subIndex].answer_way_id == 2 ||
            questionList[subIndex].answer_way_id == 3
          "
          v-model="questionList[subIndex].answer"
          @update:model-value="changeTextAnswer"
          label="答案"
        >
          <template v-if="questionList[subIndex].answer_way_id == 2" #input>
            <van-uploader
              v-model="questionList[subIndex].answerArr"
              :max-count="1"
              :before-read="beforeReadImg"
              @click-upload="clickQuestion"
            />
          </template>
          <template v-if="questionList[subIndex].answer_way_id == 3" #input>
            <van-uploader :after-read="afterReadeFile">
              <van-button
                v-if="
                  !(
                    questionList[subIndex].answer_way_id == 3 &&
                    questionList[subIndex].answer
                  )
                "
                icon="plus"
                type="primary"
                >上传附件</van-button
              >
            </van-uploader>
          </template>
        </van-field>

        <!-- 解析 -->
        <van-field label="答案解析" v-if="questionList[subIndex].answer_way_id">
          <template #input>
            <van-uploader
              v-model="questionList[subIndex].analysisArr"
              :max-count="1"
              :before-read="beforeReadPar"
              @click-upload="clickAnswer"
            />
          </template>
        </van-field>

        <iframe
          v-if="
            questionList[subIndex].answer_way_id == 3 &&
            questionList[subIndex].answer
          "
          :src="
            questionList[subIndex].answer_file_ext == 'pdf'
              ? prefix(questionList[subIndex].answer)
              : prefixFile(prefix(questionList[subIndex].answer))
          "
          frameborder="0"
          width="100%"
          height="500px"
        ></iframe>

        <van-action-sheet
          v-model:show="showAction"
          :actions="actions"
          @select="onSelectAnswerType"
        />
      </div>

      <!-- 切图 -->
      <van-popup
        v-model:show="showCut"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <vue-cropper
          v-if="isShowCro"
          :aspect-ratio="option.cropperAspectWH"
          ref="cropper"
          :src="option.imgSrc"
          :ready="option.cropImage"
          :zoom="option.cropImage"
          :cropmove="option.cropImage"
          style="width: 100%; height: 100%"
        ></vue-cropper>
        <div class="fnBTn">
          <span @click="cancel">取消</span>
          <span @click="deteFn">确定</span>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
// import "vue-cropper/dist/index.css";
// import { VueCropper } from "vue-cropper";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import {
  onBeforeUnmount,
  ref,
  shallowRef,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  defineExpose,
} from "vue";
import { Toast } from "vant";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { uploadImgBase } from "@/service/upload.js";
import { uploadFile } from "@/service/upload.js";
import { prefix, prefixFile } from "@/common/js/utils.js";

let props = defineProps(["questionList", "subIndex"]);
// questionList[subIndex].question

const emit = defineEmits([
  "changeQuestion",
  "changeAnswer",
  "addAnswer",
  "changeQuestionOption",
  "changeAnswerArr",
  "changeAnalysis",
  "addAnswerOPtions",
  "deleteAnswerOption",
  "changeSelectAnswer",
  "changeChecks",
]);

// 组件显示隐藏状态
let showAction = ref(false);
let showCut = ref(false);
let showChange = ref(false);
let isShowCro = ref(false);

let answerType = ref(0);

const actions = [
  { name: "文字答案", value: 1 },
  { name: "图片答案", value: 2 },
  // { name: "附件答案", value: 3 },
];

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
let mode = ref("default");

const editorConfig = { placeholder: "请输入内容...", MENU_CONF: {} };
editorConfig.MENU_CONF["uploadImage"] = {
  base64LimitSize: 999999999 * 1024,
};
// 富文本配置
const toolbarConfig = {
  toolbarKeys: [
    "bold", // 加粗
    "italic", // 斜体
    // "through", // 删除线
    "underline", // 下划线
    "bulletedList", // 无序列表
    "numberedList", // 有序列表
    "color", // 文字颜色
    // "insertLink", // 插入链接
    // "fontSize", // 字体大小
    // "lineHeight", // 行高
    "delIndent", // 缩进
    "indent", // 增进
    // "deleteImage", //删除图片
    "divider", // 分割线
    "uploadImage", // 上传图片
    // "insertImage", // 网络图片
    "insertTable", // 插入表格
    "justifyLeft", // 左对齐
    "justifyJustify", // 两端对齐
    "justifyCenter", // 居中对齐
    "justifyRight", // 右对齐
    "undo", // 撤销
    "redo", // 重做
    // "clearStyle", // 清除格式
    "fullScreen", // 全屏
  ],
};
// 截图工具配置
// 截图工具配置
let option = ref({
  imgSrc: "",
  autoCrop: true,
  dialogVisible: false,
  img_size: "",
  max_fuyuan_defaultSrc: [],
  // cropperAspectWH: 1, //图片剪裁宽高比例
  reader: "",
  e_photo_file: "",
  e_photo_filefile: "",
  http_cropImg: "", //带http
  cropImg: "", //默认图片不带http
});

// 问题
const handleChange = (editor) => {
  if (editor) {
    if (editor.getText()) {
      emit("changeQuestion", editor.getHtml());
    }
  }
};
defineExpose({ handleChange, editorRef });

// 答案
const changeTextAnswer = (e) => {
  emit("changeAnswer", e);
  emit("addAnswer", 1);
};

const clickChange = () => {
  showAction.value = true;
};

// 监听索引值，当索引值发生改变时，隐藏 "修改答案类型"
watch(
  () => props.subIndex,
  () => {
    showChange.value = false;
  }
);

// 附件上传
const afterReadeFile = async (file) => {
  // 验证文件类型
  if (
    file.file.type == "text/plain" ||
    file.file.type == "image/png" ||
    file.file.type == "image/jpeg"
  ) {
    Toast.fail("只能上传doc,docx,pdf,ppt,pptx,xls,xlsx类型的文件");
    return false;
  }

  let formData = new FormData();
  formData.append("file", file.file);
  let res = await uploadFile(formData);
  if (res) {
    emit("changeAnswer", res.data.file.url);
    emit("addAnswer", 3, res.data.file.suffix);
  }
};

// 取消按钮
const cancel = () => {
  isShowCro.value = false;
  showCut.value = false;
};

let upType = 0;
const clickQuestion = () => {
  upType = 1;
};
const clickAnswer = () => {
  upType = 2;
};

// 确定按钮
const cropper = ref(null);
const deteFn = async () => {
  let formData = new FormData();
  let base = cropper.value.src;

  formData.append("imgBase", base);
  let res = await uploadImgBase(formData);
  if (res) {
    if (upType == 1) {
      emit("changeAnswer", res.data.file.url);
    }
    if (upType == 2) {
      emit("changeAnalysis", res.data.file.url);
    }
    isShowCro.value = false;
    showCut.value = false;
  }
};

// 图片上传前处理
const beforeReadImg = (file) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};

const beforeReadPar = (file) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};

// 图片背景base64
function imageToBase64(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    isShowCro.value = true;
    option.value.imgSrc = reader.result;
    // option.value.detail = detail;
  };
}

// 修改选项
// const changeOPtion = (index, e) => {
//   emit("changeQuestionOption", e, index);
// };

// 选择上传答案类型（图片类型或附件类型）
const onSelectAnswerType = (e) => {
  showAction.value = false;
  answerType.value = e.value;
  showChange.value = true;
  emit("changeAnswerArr");
  emit("addAnswer", e.value);
};

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

onMounted(() => {
  const editor = editorRef.value;
});

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};

// 添加选项
const addAnswerOPtion = (index) => {
  emit("addAnswerOPtions", index);
};
// 删除选项
const deleOPtion = (index) => {
  emit("deleteAnswerOption", index);
};
// 修改
const changeSelectAnswer = (e) => {
  emit("changeAnswer", e);
  emit("changeSelectAnswer");
};

const changeChecks = (index, e) => {
  emit("changeChecks", index, e);
};
</script>

<style lang="less" scoped>
.cropper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  height: 90%;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.fnBTn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);

  span {
    height: 100%;
    font-size: 12px;
    color: #fff;
  }
  span:nth-child(1) {
    margin-left: 10px;
  }
  span:nth-child(2) {
    margin-right: 10px;
  }
}

// :deep(.van-field__label) {
//   display: flex;
//   justify-content: start;
//   align-items: center;
// }

.answerCheck {
  position: relative;
  margin-right: 15px;
  .close {
    position: absolute;
    font-size: 10px;
    color: red;
    right: 2px;
    top: -13px;
  }
}

.checks {
  position: relative;
  margin-right: 20px;
  .close {
    position: absolute;
    font-size: 10px;
    color: red;
    right: -8px;
    top: -13px;
  }
}
</style>
