<template>
  <div class="caseInfo">
    <van-nav-bar
      title="发布新学案"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <div class="DataForm">
      <van-divider
        content-position="left"
        :style="{
          color: 'rgb(25,137,250)',
          borderColor: 'rgb(25,137,250)',
        }"
        >学案习题</van-divider
      >
      <van-form>
        <van-field
          label="学案习题"
          :placeholder="`共发布 ${statistics.total} 条 ：主观题共 ${statistics.subjective} 条、 客观题共 ${statistics.objective} 条（判断题 ${statistics.judge} 条 、选择题 ${statistics.select} 条）`"
          readonly
          @click="clickUpCaseInput"
        >
          <template #button>
            <van-button size="mini" type="primary">点击上传习题</van-button>
          </template>
        </van-field>
      </van-form>
      <van-divider
        content-position="left"
        :style="{
          color: 'rgb(25,137,250)',
          borderColor: 'rgb(25,137,250)',
        }"
        >教学资源（视频、音频等）</van-divider
      >
      <van-form>
        <ul class="UpRel">
          <li v-for="(item, index) in resourceArr">
            <div class="iconBox">
              <van-image
                width="5vw"
                height="5vw"
                :src="
                  item.ext == 'mp3'
                    ? require('@/assets/images/audio.png')
                    : item.ext == 'mp4'
                    ? require('@/assets/images/video.png')
                    : require('@/assets/images/file.png')
                "
              />
            </div>
            <div class="inputBox">
              <van-field
                v-model="item.name"
                autocomplete="off"
                placeholder="输入文件名称"
                @update:model-value="changeFileName(item)"
              ></van-field>
            </div>
            <div class="studyTime">
              <div>
                学习时长<input
                  id="studyTime"
                  type="text"
                  v-model="item.estimate_duration"
                  @input="changeStudyTime(item)"
                />分
              </div>
              <div>
                <van-image
                  width="2vw"
                  height="2vw"
                  :src="require('@/assets/images/del.png')"
                  @click="
                    delRes(item.resource_id ? item.resource_id : item.id, index)
                  "
                />
              </div>
            </div>
          </li>

          <van-uploader :after-read="afterReadFile" accept="*">
            <van-button type="primary" icon="plus" size="mini"
              >添加资源</van-button
            >
          </van-uploader>
        </ul>
      </van-form>
      <van-divider
        content-position="left"
        :style="{
          color: 'rgb(25,137,250)',
          borderColor: 'rgb(25,137,250)',
        }"
        >发布设置</van-divider
      >
      <van-form>
        <van-field
          v-model="startTime"
          label="开始时间"
          readonly
          @click-input="starShowModel = true"
        ></van-field>
        <van-field
          v-model="endTime"
          label="结束时间"
          readonly
          @click-input="endShowModel = true"
        ></van-field>

        <van-field
          v-model="gradeName"
          label="发布年级"
          placeholder="请选择年级"
          readonly
          @click-input="showGradeShowAction = true"
        >
          <template #button>
            <van-button
              size="mini"
              icon="cross"
              type="danger"
              @click="clearGradAndClass"
              >清空</van-button
            >
          </template>
        </van-field>

        <van-field label="发布对象">
          <template #input>
            <!-- <van-checkbox-group v-model="relObject" direction="horizontal">
              <van-checkbox :name="1">指定班级</van-checkbox>
              <van-checkbox :name="2">指定同学</van-checkbox>
              <van-checkbox :name="3">全部</van-checkbox>
            </van-checkbox-group> -->
            <van-radio-group v-model="relObject" direction="horizontal">
              <van-radio :name="1">指定班级</van-radio>
              <van-radio :name="2">指定学生</van-radio>
              <van-radio :name="3">全年级</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          label="发布班级"
          placeholder="请选择班级"
          readonly
          v-show="relObject == 1 || relObject == 2"
        >
          <template #input>
            <van-checkbox-group
              v-model="classListIdsArr"
              direction="horizontal"
            >
              <van-checkbox v-for="item in classListArr" :name="item.id">{{
                item.className
              }}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <div class="relStudent" v-show="relObject == 2">
          <van-field label="发布同学">
            <template #input>
              <p v-if="isHaveName" style="margin: 0; color: #d1d1d1">
                请搜索添加学生
              </p>
              <van-tag
                v-for="(item, index) in studentNameArr"
                :key="item.id"
                closeable
                size="medium"
                type="primary"
                @close="closeStudentTag(index)"
              >
                {{ item.name }}
              </van-tag>
            </template>
          </van-field>
          <van-field
            v-model="studentName"
            label="搜索学生"
            placeholder="请选择同学"
            autocomplete="off"
            @update:model-value="changeName"
          >
            <template v-if="showCloseName" #button>
              <van-button size="mini" type="danger" @click="clearAndClose"
                >关闭</van-button
              >
            </template>
          </van-field>
          <ul class="selectItem">
            <li
              v-for="item in studentList"
              :key="item.id"
              @click="
                selectStudent(
                  item.studentName + item.gradeName + item.className,
                  item.id
                )
              "
            >
              {{ item.studentName }} {{ item.gradeName }} {{ item.className }}
            </li>
          </ul>
        </div>
      </van-form>
      <van-divider
        content-position="left"
        :style="{
          color: 'rgb(25,137,250)',
          borderColor: 'rgb(25,137,250)',
        }"
        >学案信息</van-divider
      >
      <van-form>
        <van-field
          label="学案名称"
          v-model="caseTitle"
          placeholder="请填写学案名称"
        ></van-field>
        <van-field
          v-model="subjectName"
          label="关联学科"
          placeholder="请选择学科"
          readonly
          @click-input="clickSelectSubject"
        ></van-field>
        <van-field label="教材类型">
          <template #input>
            <van-radio-group
              v-model="relType"
              direction="horizontal"
              @change="changeType"
            >
              <van-radio :name="1">系统教材</van-radio>
              <van-radio :name="2">自定义教材</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="bookName"
          label="书本教材"
          placeholder="请选择教材"
          :readonly="isCustom"
        >
          <template #button>
            <van-button size="mini" type="primary" @click="clickSelectBook"
              >点击选择教材</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="tree"
          label="教材目录"
          placeholder="请选择目录"
          :readonly="isCustom"
        >
          <template #button>
            <van-button size="mini" type="primary" @click="clickSelectTree"
              >点击选择目录</van-button
            >
          </template>
        </van-field>
      </van-form>
      <div class="submit">
        <van-button round type="primary" @click="saveCase">保存</van-button>
        <van-button round type="primary" @click="createCase">发布</van-button>
      </div>
    </div>

    <!-- 添加题目 -->
    <van-action-sheet
      v-model:show="showSeleCase"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="routerPush"
    />

    <!-- 年级 -->
    <van-action-sheet
      v-model:show="showGradeShowAction"
      :actions="gradeActions"
      cancel-text="取消"
      close-on-click-action
      @select="selectGrade"
    />

    <!-- 学科 -->
    <van-popup
      v-model:show="showSubjectAction"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        :columns="subjectActions"
        @cancel="showSubjectAction = false"
        @confirm="selectSubject"
      />
    </van-popup>

    <!-- 教材 -->
    <!-- <van-action-sheet
      v-model:show="showBookAction"
      :actions="bookActions"
      cancel-text="取消"
      close-on-click-action
      @select="selectBook"
    /> -->
    <van-popup
      v-model:show="showBookAction"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        :columns="bookActions"
        @cancel="showSubjectAction = false"
        @confirm="selectBook"
      />
    </van-popup>

    <!-- 目录 -->
    <van-popup v-model:show="showTree">
      <div class="treeBox">
        <c-tree
          :list="treeList"
          :defaultIds="treeDefaultId"
          @callback="clickChapterTree"
        />
      </div>
    </van-popup>

    <!-- 开始时间 -->
    <van-popup
      v-model:show="starShowModel"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :loading="isLoadingShow"
        title="选择时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="starShowModel = false"
        @confirm="startConfirmFn"
      />
    </van-popup>

    <!-- 结束时间 -->
    <van-popup
      v-model:show="endShowModel"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :loading="isLoadingShow"
        title="选择时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="endShowModel = false"
        @confirm="endConfirmFn"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { apiGetGradesList } from "@/service/grade.js";
import { apiGetClassesList } from "@/service/class.js";
import { apiGetSubjectsList } from "@/service/subject.js";
import { apiGetBooksList, apiGetBookTree } from "@/service/book.js";
import { uploadFile } from "@/service/upload.js";
import {
  apiSaveResource,
  apiStudentList,
  apiPostCase,
  apiSaveCase,
  apiCasesDraftInfo,
  apiDelRes,
  apiGetResourceList,
} from "@/service/teacher.js";
import { Toast } from "vant";
import cTree from "@/components/ChapterTree";

const router = useRouter();
const route = useRoute();
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

// 组件显示隐藏状态
let showGradeShowAction = ref(false);
let showSubjectAction = ref(false);
let starShowModel = ref(false);
let endShowModel = ref(false);
let showBookAction = ref(false);
let showTree = ref(false);
let showSeleCase = ref(false);
let isHaveName = ref(true);
let showCloseName = ref(false);

let way_id = ref(0);
// 组件数据
// 资源数组
let resourceArr = ref([]);
let fileName = ref("");
// 学习时长
let estimate_duration = ref(1);

// 年级
let gradeName = ref("");
let gradeId = ref("");
let gradeActions = ref([]);

let classListIdsArr = ref([]);
let classListArr = ref([]);

// 发布对象复选框
let relObject = ref(3);
// 发布同学
let studentName = ref("");
let studentList = ref([]);
let studentIds = ref([]);
let studentNameArr = ref([]);

let subjectActions = ref([]);
let subjectName = ref("");
let subjectId = ref("");

let isLoadingShow = ref(false);
let minDate = new Date();
let maxDate = new Date(2099, 11, 31, 23, 59, 59);
let currentDate = new Date(new Date().setHours(new Date().getHours()));
let startTime = ref("点击选择开始时间");
let endTime = ref("点击选择结束时间");

let caseTitle = ref("");
// 学案类型单选框
let relType = ref(1);
let isCustom = ref(true);

let bookActions = ref([]);
let bookName = ref("");
let bookId = ref("");

let treeList = ref([]);
let treeDefaultId = ref([0, "all"]);

let tree = ref("");
let treeId = ref("");

let statistics = ref({});

const getCaseInfo = async () => {
  let res = await apiCasesDraftInfo({ case_id: route.query.case_id });
  if (res) {
    way_id.value = res.data.setting.way_id;
    statistics.value = res.data.statistics.question;
    startTime.value = res.data.setting.start_time;
    endTime.value = res.data.setting.blocking_time;
    gradeName.value = res.data.setting.grade_str;
    gradeId.value = res.data.setting.grade_ids;
    relObject.value = res.data.setting.post_object_type_id
      ? res.data.setting.post_object_type_id
      : 3;
    res.data.setting.student_list.forEach((item) => {
      if (item) {
        isHaveName.value = false;
        studentNameArr.value.push({
          id: item.id,
          name: item.studentName + item.gradeName + item.className,
        });
        studentIds.value.push(item.id);
      }
    });

    let classRes = await apiGetClassesList({
      gradeId: res.data.setting.grade_ids,
      academicYear: userInfo.now_academicYear,
    });
    if (classRes) {
      classListArr.value = classRes.data;
      let subRes = await apiGetSubjectsList({
        gradeId: res.data.setting.grade_ids,
      });
      if (subRes) {
        subjectActions.value = subRes.data.map((item) => {
          return {
            text: item.subjectName,
            value: item.id,
          };
        });
      }
    }
    classListIdsArr.value = res.data.setting.class_ids;
    caseTitle.value = res.data.info.title;
    subjectName.value = res.data.info.subject_name;
    subjectId.value = res.data.info.subject_id;
    relType.value = res.data.info.relation_book_way_id
      ? res.data.info.relation_book_way_id
      : 1;
    bookName.value = res.data.info.book_str;
    bookId.value = res.data.info.book_id;
    treeId.value = res.data.info.tree_id;
    tree.value = res.data.info.tree_str;
  }
};

const getResourceList = async () => {
  let res = await apiGetResourceList({ case_id: route.query.case_id });
  resourceArr.value = res.data;
};
onBeforeMount(async () => {
  await getCaseInfo();
  await getResourceList();
});

// 添加新学案
const clickUpCaseInput = () => {
  showSeleCase.value = true;
  router.push({
    path: "/teacher/caseContent",
    query: { case_id: route.query.case_id },
  });
};

const actions = [
  { name: "AI切题", subname: "整份题目拍照上传，AI自动识别切题" },
  { name: "题目学案", subname: "题目逐一上传，整合为新学案后发布" },
];

const routerPush = async (e) => {
  await save();
  if (e.name == "题目学案") {
    way_id.value = 2;
    router.push({
      path: "/teacher/caseContent",
      query: { case_id: route.query.case_id },
    });
  }
  if (e.name == "AI切题") {
    // router.push({ path: "/teacher/settingBasis", query: { isAI: 1 } });
    way_id.value = 1;
    Toast.fail("该功能正在完善");
  }
};

const saveRes = async (obj) => {
  let subFormData = new FormData();
  subFormData.append("case_id", route.query.case_id);
  subFormData.append("resource_id", obj.resource_id);
  subFormData.append("name", obj.name);
  subFormData.append("url", obj.url);
  subFormData.append("estimate_duration", obj.estimate_duration);
  subFormData.append("ext", obj.ext);
  let subRes = await apiSaveResource(subFormData);
  if (subRes) {
    return subRes;
  } else {
    return false;
  }
};

// 点击上传资源
const afterReadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file.file);

  let res = await uploadFile(formData);
  if (res) {
    let obj = {
      resource_id: "",
      name: res.data.file.name,
      url: res.data.file.url,
      estimate_duration: estimate_duration.value,
      ext: res.data.file.suffix,
    };
    let subRes = await saveRes(obj);
    if (subRes) {
      resourceArr.value.push({
        resource_id: subRes.data.resource_id,
        name: res.data.file.name,
        estimate_duration: estimate_duration.value,
        url: res.data.file.url,
        ext: res.data.file.suffix,
      });

      estimate_duration.value = 1;
      fileName.value = res.data.file.name;
    }
  }
};

// 修改资源名称
let resTime = null;
const changeFileName = (obj) => {
  // 防抖
  if (resTime) {
    clearTimeout(resTime);
  }
  resTime = setTimeout(() => {
    saveRes(obj);
  }, 500);
};

// 修改学习时长
let stuTime = null;
function changeStudyTime(obj) {
  if (stuTime) {
    clearTimeout(stuTime);
  }
  stuTime = setTimeout(() => {
    saveRes(obj);
  }, 500);
}

// 删除资源
const delRes = async (id, index) => {
  let formData = new FormData();
  formData.append("case_id", route.query.case_id);
  formData.append("resource_id", id);
  let res = await apiDelRes(formData);
  if (res) {
    resourceArr.value.splice(index, 1);
    Toast.success("删除成功");
  }
};

// 获取年级列表
const getGrade = async () => {
  let res = await apiGetGradesList();
  gradeActions.value = res.data.map((item) => {
    return {
      name: item.gradeName,
      value: item.id,
    };
  });
};
getGrade();

// 年级清空按钮
const clearGradAndClass = () => {
  gradeName.value = "";
  classListArr.value = "";
  // classListIdsArr.value = [];
  gradeId.value = "";
  classListIdsArr.value = [];
};

// 选择年级请求获取班级列表和获取学科列表接口
const selectGrade = async (e) => {
  gradeName.value = e.name;
  gradeId.value = e.value;

  let res = await apiGetClassesList({
    gradeId: e.value,
    academicYear: userInfo.now_academicYear,
  });
  if (res) {
    classListArr.value = res.data;
  }

  let subRes = await apiGetSubjectsList({ gradeId: e.value });
  subjectActions.value = subRes.data.map((subItem) => {
    return {
      text: subItem.subjectName,
      value: subItem.id,
    };
  });
};

let NameTimer = null;
// 搜索学生
const changeName = async () => {
  let pop = document.querySelector(".selectItem");
  pop.style.display = "block";
  showCloseName.value = true;
  if (!studentName.value) {
    pop.style.display = "none";
    showCloseName.value = true;
  }

  // 防抖
  if (NameTimer) {
    clearTimeout(NameTimer);
  }
  NameTimer = setTimeout(async () => {
    let res = await apiStudentList({
      gradeId: gradeId.value,
      classId: classListIdsArr.value.join(),
      searchName: studentName.value,
    });
    if (res) {
      if (res.data.length) {
      } else {
        Toast.fail("没有找到该生");
      }
      studentList.value = res.data;
    }
  }, 500);
};

const clearAndClose = () => {
  let pop = document.querySelector(".selectItem");
  pop.style.display = "none";
  showCloseName.value = false;
};

const closeStudentTag = (index) => {
  studentIds.value.splice(index, 1);
  studentNameArr.value.splice(index, 1);

  if (!studentNameArr.value.length) {
    isHaveName.value = true;
  }
};

const selectStudent = (name, id) => {
  isHaveName.value = false;
  studentNameArr.value.push({ show: true, name: name });
  studentIds.value.push(id);
  studentName.value = "";
};

// 修改学案类型
const changeType = (e) => {
  if (e == 2) {
    isCustom.value = false;
  }
  if (e == 1) {
    isCustom.value = true;
  }
};

// 选择学科
const clickSelectSubject = () => {
  if (gradeName.value) {
    showSubjectAction.value = true;
  } else {
    Toast.fail("请先选择年级");
  }
};

const selectSubject = async (e) => {
  subjectName.value = e.text;
  subjectId.value = e.value;
  showSubjectAction.value = false;

  let res = await apiGetBooksList({
    gradeId: gradeId.value,
    subjectId: subjectId.value,
  });
  if (res) {
    bookActions.value = res.data.map((item) => {
      return {
        text: item.bookName,
        value: item.id,
      };
    });
  }
};

// 选择教材
const clickSelectBook = () => {
  if (subjectName.value) {
    showBookAction.value = true;
  } else {
    Toast.fail("请先选择学科");
  }
};

const selectBook = async (e) => {
  bookName.value = e.text;
  bookId.value = e.value;
  showBookAction.value = false;

  let res = await apiGetBookTree({ bookId: bookId.value });
  treeList.value = res.data;
};

// 选择目录
const clickSelectTree = () => {
  if (bookName.value) {
    showTree.value = true;
  } else {
    Toast.fail("请先选择教材");
  }
};
const clickChapterTree = (e) => {
  tree.value = e.title;
  treeId.value = e.id;
};

// 保存
const save = async () => {
  let setting = {
    way_id: 2,
    start_time: startTime.value,
    blocking_time: endTime.value,
    post_object_type_id: relObject.value,
    grade_ids: gradeId.value,
    class_ids: classListIdsArr.value.join(),
    student_ids: studentIds.value.join(),
  };
  let info = {
    title: caseTitle.value,
    subject_id: subjectId.value,
    relation_book_way_id: relType.value,
    book_id: relType.value == 1 ? bookId.value : "",
    tree_id: relType.value == 1 ? treeId.value : "",
    book_str: relType.value == 2 ? bookName.value : "",
    tree_str: relType.value == 2 ? tree.value : "",
  };
  let formData = new FormData();
  formData.append("case_id", route.query.case_id);
  formData.append("setting", JSON.stringify(setting));
  formData.append("info", JSON.stringify(info));
  let saveRes = await apiSaveCase(formData);
  return saveRes;
};

// 发布按钮
const createCase = async () => {
  let saveRes = await save();
  if (saveRes) {
    let formData = new FormData();
    formData.append("case_id", route.query.case_id);
    let res = await apiPostCase(formData);
    if (res) {
      Toast.success("发布成功");
      router.go(-1);
    }
  }
};

const saveCase = async () => {
  let res = await save();
  if (res) {
    Toast.success("保存成功");
    // router.go(-1);
  }
};

const goBack = () => {
  router.go(-1);
};

// 开始时间
const startConfirmFn = (val) => {
  starShowModel.value = false;
  endShowModel.value = false;
  let year = val.getFullYear();
  let month = val.getMonth() + 1;
  let day = val.getDate();
  let hour = val.getHours();
  let minute = val.getMinutes();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 1 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minute >= 0 && minute <= 9) {
    minute = "0" + minute;
  }
  startTime.value = year + "-" + month + "-" + day + " " + hour + ":" + minute;
};
// 结束时间
const endConfirmFn = (val) => {
  starShowModel.value = false;
  endShowModel.value = false;
  let year = val.getFullYear();
  let month = val.getMonth() + 1;
  let day = val.getDate();
  let hour = val.getHours();
  let minute = val.getMinutes();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 1 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minute >= 0 && minute <= 9) {
    minute = "0" + minute;
  }
  endTime.value = year + "-" + month + "-" + day + " " + hour + ":" + minute;
};
</script>

<style lang="less" scoped>
.DataForm {
  padding: 20px;
}

:deep(.van-form) {
  background-color: #fff;

  // overflow: hidden;
  margin-bottom: 20px;
}

:deep(.van-divider) {
  margin: 0px;
}

.submit {
  display: flex;
  justify-content: space-between;
  :deep(.van-button) {
    width: 45%;
  }
}

.UpRel {
  padding: 5px 10px;
  li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    .inputBox {
      flex: 1;
    }
    .studyTime {
      width: 150px;
      font-size: 12px;
      display: flex;
      align-items: center;
      input {
        border: 1px solid #ddd;
        width: 20%;
        height: 20%;
        margin: 0 5px;
      }
    }
  }
}

.relStudent {
  position: relative;
  .selectItem {
    width: 30%;
    max-height: 200px;
    overflow: scroll;
    position: absolute;
    z-index: 1;
    left: 10%;
    // transform: translate(-75%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 15px;
    box-sizing: border-box;
    border-radius: 5px;
    display: none;
    transition: 1s;

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -10px;
      left: 42px;
    }
    li {
      text-align: center;
      line-height: 25px;
      color: #fff;
      font-size: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.upFile {
  :deep(.van-popup) {
    border-radius: 10px;
  }
}

:deep(.van-checkbox) {
  margin-bottom: 8px;
}

.treeBox {
  width: 50vw;
  height: 60vw;
  position: relative;
  padding: 10px;
}
</style>
